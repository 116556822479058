var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SpaceDataList", {
    attrs: {
      spaceData: _vm.researchSpaces,
      spaceType: _vm.spaceTypes.RESEARCH_SPACE,
      spaceIcon: "mdi-beaker",
      headerTitle: "Research Projects",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }